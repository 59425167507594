import { Flex, FlexProps, Tooltip, chakra } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'

import { isWithinGoalRange } from '../../utils/goal'

export type ScoreBadgeProps = {
  score: number
  maxScore: number
  goal?: {
    min?: number
    max?: number
  } | null
} & FlexProps

export const ScoreBadge: React.FC<ScoreBadgeProps> = ({
  score,
  maxScore,
  goal,
  ...restProps
}) => {
  const formattedScore = i18n.number(score, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  })
  const formattedMaxScore = i18n.number(maxScore, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const isSuccess = isWithinGoalRange(score, goal)
  return (
    <Tooltip label={t`Score: ${formattedScore} out of ${formattedMaxScore}`}>
      <Flex
        display="inline-flex"
        fontSize="lg"
        borderRadius="md"
        align="center"
        justify="center"
        border="1px"
        borderColor="gray.300"
        textAlign="center"
        px={1}
        boxShadow="sm"
        {...restProps}
      >
        <chakra.span>
          <chakra.span
            fontWeight="medium"
            color={isSuccess ? 'success' : 'text'}
          >
            {formattedScore}
          </chakra.span>
          <chakra.span fontSize="0.75em" color="textMuted">
            /{formattedMaxScore}
          </chakra.span>
        </chakra.span>
      </Flex>
    </Tooltip>
  )
}
